import { t } from 'i18next';
import { format, parseISO } from 'date-fns';

import * as Adapters from '../adapters/index';
import Request, { API, handleError, PRICES_PATH } from './request';
import { DATE_FORMAT, DEFAULT_RULE_ID } from '../../constants';
import {
  BONUS_OPTIONS,
  BUSINESS_OPTIONS,
  CONSUMPTION_OPTIONS,
  INCREASE_LOGIC_VALUES,
  PRICE_TYPE_OPTIONS,
} from '../../constants/data';
import Helpers from '../helpers';
import { adaptDictionary } from '../adapters/index';

// TODO: Cover by tests [L]
export function getPendingCalculationsStatus() {
  const url = `${PRICES_PATH}/execute/status`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function retryPriceAdjustment(id) {
  const url = `${PRICES_PATH}/execute/${id}/retry`;

  return Request.post(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function deletePriceAdjustment(id) {
  const url = `${PRICES_PATH}/execute/${id}`;

  return Request.delete(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function getDashboardProducts() {
  const url = `${PRICES_PATH}/dashboard/products`;

  return Request.get(url)
    .then(({ data = {} }) => Adapters.dashboardProducts(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getDashboardOverview() {
  const url = `${PRICES_PATH}/dashboard/overview`;

  return Request.get(url)
    .then(({ data = {} }) => Adapters.dashboardOverview(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getDashboardSalesChannels() {
  const url = `${PRICES_PATH}/dashboard/salesChannels`;

  return Request.get(url)
    .then(({ data = {} }) => Adapters.dashboardSalesChannels(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getDashboardContractMargin({ energyTypeId, limit = 250 }) {
  const url = `${PRICES_PATH}/dashboard/contractMargin?energyTypeId=${energyTypeId}&limit=${limit}`;

  // TODO: Cover by tests [L]
  function adaptDashboardContractMargin(data = {}) {
    return { treeMapSource: data.treeMapSource };
  }

  return Request.get(url)
    .then(({ data = {} }) => adaptDashboardContractMargin(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export function getRules() {
  const url = `${PRICES_PATH}/rules`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getProducts() {
  const url = `${PRICES_PATH}/products`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getCampaigns() {
  const url = `${PRICES_PATH}/campaigns`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getEnergySubTypes() {
  const url = `${PRICES_PATH}/energy-subtypes`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getSalesChannels() {
  const url = `${PRICES_PATH}/sales-channels`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getEnergyTypes() {
  const url = `${PRICES_PATH}/energy-types`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getMargins() {
  const url = `${PRICES_PATH}/margins`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
export function getChurnModels() {
  const url = `${PRICES_PATH}/churn-models`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function getRuleCalculations({ ruleId, filters, version }) {
  const versionParam = version ? `?version=${version}` : '';
  const url = `${PRICES_PATH}/rules/${ruleId || DEFAULT_RULE_ID}/calculations${versionParam}`;

  // TODO: Cover by tests [L]
  function adaptRulePriceCalculations(data = {}) {
    return data?.calculations || {};
  }

  return Request.post(url, { filters })
    .then(({ data = {} }) => adaptRulePriceCalculations(data))
    .catch((error) => {
      throw handleError(error);
    });
}
export async function getEffectPredictions({ ruleId, filters, version } = {}, options = {}) {
  const calculations = await getRuleCalculations({ ruleId, filters, version });
  return Adapters.effectPredictions({ calculations }, options);
}

export async function getOptions() {
  const dictionaries = await Promise.all([
    getRules(),
    getProducts(),
    getCampaigns(),
    getEnergySubTypes(),
    getSalesChannels(),
    getEnergyTypes(),
    getMargins(),
    getChurnModels(),
  ]);

  return Adapters.getOptions(dictionaries.reduce((result, item) => ({ ...result, ...item }), {}));
}

export async function getDictionary() {
  const dictionaryFromBe = await Promise.all([
    getRules(),
    getProducts(),
    getCampaigns(),
    getEnergySubTypes(),
    getSalesChannels(),
    getEnergyTypes(),
    getMargins(),
    getChurnModels(),
  ]).then((responses) => responses.reduce((result, item) => {
    const { success, ...option } = item;
    return { ...result, ...option };
  }, {}));

  const localDictionary = {
    consumptions: CONSUMPTION_OPTIONS,
    priceTypes: PRICE_TYPE_OPTIONS,
    bonusOptions: BONUS_OPTIONS,
    businessOptions: BUSINESS_OPTIONS,
  };

  const dictionary = { ...dictionaryFromBe, ...localDictionary };
  return adaptDictionary(dictionary);
}

export function getCurrentRuleConfigs({ ruleId, version }) {
  const versionParam = version ? `?version=${version}` : '';
  const url = `${PRICES_PATH}/rules/${ruleId || DEFAULT_RULE_ID}${versionParam}`;

  return Request.get(url)
    .then(({ data = {} }) => Adapters.currentRuleConfigs(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export function getRulesMapping() {
  const url = `${PRICES_PATH}${API.RULES_MAPPING}`;

  return Request.get(url)
    .then(({ data = {} }) => data.mapping)
    .catch((error) => {
      throw handleError(error);
    });
}

export function sendRulesMapping(data) {
  const url = `${PRICES_PATH}${API.RULES_MAPPING}`;

  return Request.post(url, data)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function adaptWholesaleData({ prices } = {}) {
  const pricesObj = prices.reduce((result, p) => {
    const date = parseISO(p.date);
    const key = format(date, 'MMM yy');
    // eslint-disable-next-line no-param-reassign
    if (!result[key]) result[key] = {};
    // eslint-disable-next-line no-param-reassign
    result[key][p.type] = p.price;
    return result;
  }, {});

  const dataPoints = Object.keys(pricesObj);

  const {
    effectivePrices, marketConditions, clientHedges, hedgingRatio, historic,
  } = dataPoints.reduce(
    (result, key, i) => {
      // eslint-disable-next-line no-param-reassign
      result.effectivePrices[i] = pricesObj[key].effective_price || 0;
      // eslint-disable-next-line no-param-reassign
      result.marketConditions[i] = pricesObj[key].market_conditions || 0;
      // eslint-disable-next-line no-param-reassign
      result.clientHedges[i] = pricesObj[key].client_hedges || 0;
      // eslint-disable-next-line no-param-reassign
      result.historic[i] = pricesObj[key].historic || 0;
      if (pricesObj[key].client_hedges && pricesObj[key].effective_price) {
        // eslint-disable-next-line no-param-reassign
        result.hedgingRatio[i] = -(pricesObj[key].market_conditions - pricesObj[key].effective_price)
          / (pricesObj[key].client_hedges - pricesObj[key].market_conditions);
      } else {
        // eslint-disable-next-line no-param-reassign
        result.hedgingRatio[i] = 0;
      }
      return result;
    },
    {
      effectivePrices: [],
      marketConditions: [],
      clientHedges: [],
      hedgingRatio: [],
      historic: [],
    },
  );

  const chart = {
    series: [
      {
        type: 'line',
        color: '#4CAF50',
        data: effectivePrices,
        name: 'Effective price',
        // name: t('Effective price'), // TODO: Tricky case, need invetigate
      },
      {
        type: 'line',
        color: '#F44336',
        data: marketConditions,
        name: 'Market conditions',
        // name: t('Market conditions'),
      },
      {
        type: 'line',
        color: '#FF9800',
        data: clientHedges,
        name: 'Company hedges',
        // name: t('Company hedges'),
      },
      {
        type: 'line',
        color: '#2196f3',
        data: historic,
        name: 'Historic',
        // name: t('Historic'),
      },
      {
        type: 'column',
        color: '#CCCCCC',
        data: hedgingRatio,
        name: 'Hedging ratio',
        // name: t('Hedging ratio'),
      },
    ],
    xaxis: {
      dataPoints,
    },
  };

  return {
    chart,
    prices,
  };
}

export function getWholesaleData({
  energyTypeId, loadProfile, from, to,
}) {
  const buildPeriodQuery = () => `from=${from}&to=${to}&energyTypeId=${energyTypeId}&loadProfile=${loadProfile}`;
  const url = `${PRICES_PATH}/wholesale?${buildPeriodQuery(energyTypeId, from, to)}`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function adaptWholesalePricesDataToBE({
  month, year, type, price, energyTypeId, loadProfile,
} = {}) {
  return [{
    date: Helpers.generateFormattedDate({ year, month }),
    type,
    energyTypeId,
    loadProfile,
    price: price || null,
  }];
}

export function setWholesalePrices(data) {
  const url = `${PRICES_PATH}/wholesale`;

  return Request.post(url, data)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function getCurrentRuleConfigsContracts({
  ruleId, limit, offset, version,
}) {
  const versionParam = version ? `&version=${version}` : '';
  const url = `${PRICES_PATH}/rules/${
    ruleId || DEFAULT_RULE_ID
  }/contracts?limit=${limit}&offset=${offset}${versionParam}`;

  // TODO: Cover by tests [L]
  function adaptRuleContracts(data = {}) {
    const adaptedData = {
      ...data,
      isProcessing: data.total !== data.totalProcessed,
    };
    delete adaptedData.success;

    return adaptedData;
  }

  return Request.post(url)
    .then(({ data = {} }) => adaptRuleContracts(data))
    .catch((error) => {
      throw handleError(error);
    });
}

// TODO: Add server side pagination
export function getRuleHistory({ ruleId }) {
  const url = `${PRICES_PATH}/rules/${ruleId || DEFAULT_RULE_ID}/versions`;

  function adaptRuleHistory({ versions = [] } = {}) {
    return versions.map((version = {}) => {
      const activatedBy = version?.activatedBy || {};

      return {
        ...version,
        author: `${activatedBy.firstName} ${activatedBy.lastName}`,
        date: format(new Date(version.createdAt), DATE_FORMAT),
      };
    });
  }

  return Request.get(url)
    .then(({ data = {} }) => adaptRuleHistory(data))
    .catch((error) => {
      throw handleError(error);
    });
}

export function touchRuleHistory({ ruleId, version }) {
  const url = `${PRICES_PATH}/rules/${ruleId || DEFAULT_RULE_ID}/versions/${version}/touch`;

  return Request.post(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function createNewRule({ name }) {
  const url = `${PRICES_PATH}/rules`;

  const adaptCreateNewRuleResult = ({ rule = {} } = {}) => ({ ruleId: rule.ruleId });

  return Request.post(url, { name, data: { increaseLogic: INCREASE_LOGIC_VALUES.DO_NOT_INCREASE } })
    .then(({ data = {} }) => adaptCreateNewRuleResult(data))
    .catch((error) => {
      throw handleError(error);
    });
}

// TODO: Cover by tests [L] (should be moved to adapters?)
export function adaptRuleContractData(contractData = {}) {
  const LEGEND = {
    REVENUE: t('Revenue'),
    COSTS: t('Costs'),
    ADJUSTMENT_REVENUE: t('Adjustment Revenue'),
    ADJUSTMENT_COSTS: t('Adjustment Costs'),
  };

  const { details: historyDetails = {} } = contractData?.history?.data || {};
  const { details: adjustmentDetails = {} } = contractData?.adjustment?.data || {};
  const { contract = {} } = contractData || {};

  const lastHistory = Object.keys(historyDetails).slice(-1)[0];
  const chartCategories = [
    ...Object.keys(historyDetails),
    ...Object.keys(adjustmentDetails).filter((date) => date !== lastHistory),
  ];

  const groupedInfo = chartCategories.reduce(
    (result, category) => {
      // data
      result.data[LEGEND.COSTS].push(historyDetails[category]?.costs?.total || null);
      result.data[LEGEND.REVENUE].push(historyDetails[category]?.revenue?.total || null);
      result.data[LEGEND.ADJUSTMENT_COSTS].push(adjustmentDetails[category]?.costs?.total || null);
      result.data[LEGEND.ADJUSTMENT_REVENUE].push(adjustmentDetails[category]?.revenue?.total || null);

      // metadata
      // eslint-disable-next-line no-param-reassign
      result.metaData[LEGEND.COSTS][category] = {
        net: historyDetails[category]?.costs,
        gross: historyDetails[category]?.costsGross,
        showGroups: true,
      };
      // eslint-disable-next-line no-param-reassign
      result.metaData[LEGEND.REVENUE][category] = {
        net: historyDetails[category]?.revenue,
        gross: historyDetails[category]?.revenueGross,
        consumption: contract.consumption,
      };
      // eslint-disable-next-line no-param-reassign
      result.metaData[LEGEND.ADJUSTMENT_COSTS][category] = {
        net: adjustmentDetails[category]?.costs,
        gross: adjustmentDetails[category]?.costsGross,
        showGroups: true,
      };
      // eslint-disable-next-line no-param-reassign
      result.metaData[LEGEND.ADJUSTMENT_REVENUE][category] = {
        net: adjustmentDetails[category]?.revenue,
        gross: adjustmentDetails[category]?.revenueGross,
        consumption: contract.consumption,
      };

      // eslint-disable-next-line no-param-reassign
      delete result.metaData[LEGEND.ADJUSTMENT_REVENUE][category]?.changes; // TODO: Add this info to the chart tooltip [L]

      return result;
    },
    {
      data: {
        [LEGEND.COSTS]: [],
        [LEGEND.REVENUE]: [],
        [LEGEND.ADJUSTMENT_REVENUE]: [],
        [LEGEND.ADJUSTMENT_COSTS]: [],
      },
      metaData: {
        [LEGEND.COSTS]: {},
        [LEGEND.REVENUE]: {},
        [LEGEND.ADJUSTMENT_REVENUE]: {},
        [LEGEND.ADJUSTMENT_COSTS]: {},
      },
    },
  );

  const chartData = Object.keys(groupedInfo.data).map((key) => {
    const groupData = groupedInfo.data[key];
    return { name: key, data: groupData };
  });

  // console.log('adaptRuleContractData', { contractData, adaptedData }); // TODO: remove!

  return {
    chart: {
      data: chartData,
      categories: chartCategories,
      metaData: groupedInfo.metaData,
    },
  };
}

export function getRuleContractData({ ruleId, contractId, version = '' }) {
  const versionParam = version ? `?version=${version}` : '';
  const url = `${PRICES_PATH}/rules/${ruleId || DEFAULT_RULE_ID}/contracts/${contractId}/details${versionParam}`;

  return Request.get(url)
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getPriceAdjustmentData(params = {}, options = {}) {
  const url = `${PRICES_PATH}/segments`;

  return Request.post(url, Adapters.priceAdjustmentParams(params))
    .then(({ data = {} }) => Adapters.priceAdjustmentData(data, options))
    .catch((error) => {
      throw handleError(error);
    });
}

export async function getExecutedPriceAdjustmentData(params = {}, options = {}) {
  const url = `${PRICES_PATH}/execute/list`;

  return Request.post(url, {
    mode: params.mode,
    filters: params.filterBy,
  })
    .then(({ data: { contracts = [] } = {} }) => contracts.map((contract) => Adapters.contractToPriceAdjustment(contract, options)))
    .catch((error) => {
      throw handleError(error);
    });
}

export async function downloadExecutedPriceAdjustmentData(params = {}) {
  const url = `${PRICES_PATH}/execute/list?format=xlsx`;

  return Request.post(
    url,
    {
      mode: params.mode,
      filters: params.filterBy,
    },
    { responseType: 'blob' },
  )
    .then((response) => {
      const fileName = decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]);
      const type = response.headers['content-type'];
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data], { type }));
      link.setAttribute('download', fileName);
      link.click();
    })
    .catch((error) => {
      throw handleError(error);
    });
}

export async function approveAll(filters) {
  const url = `${PRICES_PATH}/execute/approve`;

  return Request.post(url, { filters, approved: true }).catch((error) => {
    throw handleError(error);
  });
}

export async function downloadContracts(params = {}) {
  const url = `${PRICES_PATH}/contracts/export?format=xlsx`;

  return Request.post(
    url,
    {
      filters: params.filterBy,
      mode: params.mode,
    },
    { responseType: 'blob' },
  )
    .then((response) => {
      const fileName = decodeURIComponent(response.headers['content-disposition'].split('filename=')[1]);
      const type = response.headers['content-type'];
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data], { type }));
      link.setAttribute('download', fileName);
      link.click();
    })
    .catch((error) => {
      throw handleError(error);
    });
}

export async function updateContractStatus(contractId, accept) {
  const url = `${PRICES_PATH}/execute/${contractId}`;

  return Request.patch(url, {
    approved: accept,
  })
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function updatePriceAdjustmentItem(params) {
  const url = `${PRICES_PATH}/segments`;

  return Request.patch(url, Adapters.updatePriceAdjustmentParams(params))
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function updateIncreaseRuleConfig(updatedRuleConfigs) {
  const url = `${PRICES_PATH}/rules/${updatedRuleConfigs.ruleId}`;

  return Request.put(url, Adapters.increaseRuleConfigToBE(updatedRuleConfigs))
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}

export function saveIncreaseRuleConfig(updatedRuleConfigs) {
  const url = `${PRICES_PATH}/rules/${updatedRuleConfigs.ruleId}`;

  return Request.put(url, Adapters.increaseRuleConfigToBE(updatedRuleConfigs))
    .then(({ data = {} }) => data)
    .catch((error) => {
      throw handleError(error);
    });
}
