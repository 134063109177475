import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

const DEFAULTS = {
  SERVER_ERROR: null,
};

const useOperationWrapper = ({ errorCb = (f) => f } = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(DEFAULTS.SERVER_ERROR);

  const operationWrapper = useCallback(async (cb, { enableLoading = true, delay = null } = {}) => {
    try {
      if (enableLoading) {
        setTimeout(() => {
          setIsLoading(true);
        }, 0);
      }
      setServerError(DEFAULTS.SERVER_ERROR);

      if (delay) {
        setTimeout(async () => {
          await cb();
        }, delay);
      } else {
        await cb();
      }
    } catch (error) {
      toast.error(error.message);
      setServerError(error);
      console.error(error);
      errorCb();
    } finally {
      if (enableLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      }
    }
  }, []);

  return {
    operationWrapper,
    serverError,
    isLoading,
  };
};

export default useOperationWrapper;
